<template>
  <v-card>
    <v-card-title
      class="error--text text--darken-1 headline error--text text-uppercase font-weight-bold text--darken-1"
    >
      <v-icon class="mr-2 mt-n1" color="error"
        >mdi-account-group</v-icon
      >
      User
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-card-title>
    <p class="grey--text ml-8 mt-n5">List of all system users</p>
    <v-divider></v-divider>

    <v-row class="pa-4" justify="space-between">
      <v-col cols="5">
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 text-capitalize"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus-thick</v-icon> New
            </v-btn>
            <v-divider></v-divider>
          </template>
          <v-card>
            <v-form ref="form" v-model="valid">
              <v-card-title>
                <span class="error--text text--darken-1">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="user.company.company_name"
                        label="Company Name"
                        :rules="nameRules"
                        disabled
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.name"
                        label="User Name"
                        :rules="nameRules"
                        outlined
                        dense
                        color="grey"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="emailRules"
                        outlined
                        dense
                        color="grey"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.phone"
                        label="Phone e.g +254712345678"
                        :rules="nameRules"
                        outlined
                        dense
                        color="grey"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" md="6">
                      <v-select
                        v-model="editedItem.role_id"
                        :items="roles"
                        label="Select Role"
                        item-text="name"
                        item-value="id"
                        color="grey"
                        dense
                        outlined
                        class="d-flex align-end"
                        :rules="selectRules"
                      ></v-select>
                    </v-col>

                    <v-col cols="6" md="6">
                      <div class="mx-auto text-center">
                        <h4>User Image</h4>
                        <image-input v-model="logoFileToBeUploaded">
                          <div slot="activator">
                            <v-avatar
                              size="100px"
                              v-ripple
                              v-if="!logoFileToBeUploaded.imageURL"
                              class="grey lighten-3"
                            >
                              <span>Click to add image</span>
                            </v-avatar>
                            <v-avatar
                              size="100px"
                              v-ripple
                              v-else
                              class="grey lighten-3"
                              style="border: 1px solid grey"
                              border
                            >
                              <v-img
                                max-height="100px"
                                max-width="100px"
                                v-model="logoFileToBeUploaded"
                                class="grey lighten-3"
                                rounded
                                :src="logoFileToBeUploaded.imageURL"
                                :lazy-src="logoFileToBeUploaded.imageURL"
                              ></v-img>
                            </v-avatar>
                          </div>
                        </image-input>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  class="text-capitalize mx-1"
                  dark
                  @click="close"
                >
                  Cancel <v-icon small>mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize mx-1"
                  :loading="loading"
                  @click="save"
                >
                  Submit <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-treeview
          :active.sync="active"
          :items="items"
          :open.sync="open"
          activatable
          color="primary"
          transition
          open-all
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="!item.children"> mdi-account </v-icon>
          </template>
          <template v-slot:append="{ item }">
            <v-btn
              color="grey"
              class="mx-1 my-3"
              fab
              x-small
              dark
              @click="editItem(item)"
              v-if="!item.children"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="error"
              class="mx-1 my-3"
              fab
              x-small
              dark
              @click="deleteItem(item)"
              v-if="!item.children"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-treeview>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="d-flex text-center">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            Select a User
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <v-avatar v-if="selected.image_url" size="100">
                <v-img
                  :src="selected.image_url"
                  :lazy-src="selected.image_url"
                ></v-img>
              </v-avatar>
               <v-avatar v-else size="100">
                <v-img
                  :src="selected.avatar"
                  :lazy-src="selected.avatar"
                ></v-img>
              </v-avatar>
              <h3 class="headline mb-2">
                {{ selected.name }}
              </h3>
              <div class="primary--text mb-2">
                {{ selected.role.title }}
              </div>
              <div class="blue--text mb-2">
                {{ selected.email }}
              </div>
              <div class="blue--text subheading font-weight-bold">
                {{ selected.username }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-row class="text-left" tag="v-card-text">
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                Company:
              </v-col>
              <v-col>{{ selected.company.company_name }}</v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                Website:
              </v-col>
              <v-col>
                <a :href="`//${selected.company.website}`" target="_blank">{{
                  selected.company.website
                }}</a>
              </v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                Phone:
              </v-col>
              <v-col>{{ selected.phone }}</v-col>
              <v-col class="text-right mr-4 mb-2" tag="strong" cols="5">
                Location:
              </v-col>
              <v-col>{{ selected.company.physical_location }}</v-col>
            </v-row>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import UserService from "../../services/user.service";
import ImageInput from "@/components/ImageInput.vue";
import moment from "moment";

export default {
  components: {
    ImageInput,
  },
  data: () => ({
    active: [],
    avatar: null,
    loading: false,
    open: [],
    users: [],
    roles: [],
    // users end
    valid: true,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "Valid E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    selectRules: [(v) => !!v || "Select is required"],
    dialog: false,
    headers: [
      {
        text: "User Name",
        align: "start",
        filterable: true,
        value: "name",
      },
      {
        text: "Company Name",
        value: "company",
      },
      {
        text: "Email Address",
        value: "email",
      },
      {
        text: "Phone",
        value: "phone",
      },
      {
        text: "Date created",
        value: "created_at",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      company: {
        company_name: "",
      },
      email: "",
      phone: "",
    },
    defaultItem: {
      name: "",
      company: {
        company_name: "",
      },
      email: "",
      phone: "",
    },
    logoFileToBeUploaded: {},
    oldImageURL: null,
    loadingUpload: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    items() {
      return [
        {
          name: "System Users",
          children: this.users,
        },
      ];
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.users.find((user) => user.id === id);
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllUsers();
    this.getAllRoles();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    getAllUsers() {
      return UserService.getAllCompanyUsers().then(
        (response) => {
          if (response.status == 200) {
            this.users = response.data.data;
            console.log(this.users);
          } else {
            this.users = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.users = [];
          console.log(error);
        }
      );
    },
    getAllRoles() {
      return UserService.getAllRoles().then(
        (response) => {
          if (response.status == 200) {
            this.roles = response.data.data.roles;
            console.log(this.roles);
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.roles = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (item.image) {
        this.logoFileToBeUploaded.imageFile = this.dataURLtoFile(
          item.image,
          "image"
        );
        this.logoFileToBeUploaded.imageURL = item.image_url;
        console.log(this.logoFileToBeUploaded.imageURL);
      }else{
        this.logoFileToBeUploaded.imageFile = ''
        this.logoFileToBeUploaded.imageURL = item.avatar;

      }
      this.dialog = true;
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    deleteItem(item) {
      const index = this.users.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.users.splice(index, 1)) {
        UserService.deleteUser(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async save() {
      this.$refs.form.validate();
      this.editedItem.callback =process.env.VUE_BASE_URL+"login#/reset-password";
      if (this.valid) {
        this.loading = true;
        // update
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem);
          if (this.logoFileToBeUploaded.imageFile) {
            let file = this.logoFileToBeUploaded.imageFile;
            let base64file = null;
            await this.getBase64(file).then((data) => (base64file = data));
            this.editedItem.image = base64file;
          }
          this.editedItem.company_id = this.user.company.id;
          UserService.updateUser(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("auth/updateUser", this.user);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.getAllUsers();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          if (this.logoFileToBeUploaded.imageFile) {
            let file = this.logoFileToBeUploaded.imageFile;
            let base64file = null;
            await this.getBase64(file).then((data) => (base64file = data));
            this.editedItem.image = base64file;
          }
          this.editedItem.company_id = this.user.company.id;
          UserService.storeUser(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.users.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.getAllUsers();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
